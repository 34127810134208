<template>
  <nav class="tw-flex tw-flex-col tw-items-center tw-border-b tw-border-gray-200 tw-p-4 tw-py-4">
    <div class="tw-flex tw-w-screen tw-max-w-screen-xl tw-items-center tw-justify-between tw-px-6">
      <NuxtLink :to="{ name: 'index' }" aria-label="Home">
        <img
          :src="logoUrl"
          :alt="branding.name"
          class="tw-max-w-52 tw-mr-3 tw-h-auto tw-max-h-14 tw-w-auto tw-max-w-sm"
        />
      </NuxtLink>
      <TorticityLogo v-if="cobrandedExperience" />
    </div>
  </nav>
</template>

<script setup lang="ts">
import TorticityLogo from '~/assets/icons/torticity-logo.svg'

const branding = useBrand()
const cobrandedExperience = computed(() => branding.value.brandingExperience === 'cobrand')

const logoUrl = computed(() => {
  return branding.value.svgLogoUrl || branding.value.pngLogoUrl
})
</script>
